const dev = false;//process.env && process.env.NODE_ENV == "development";

export const log = (...arg) => {
    console.log(...arg);
}

export const c = {
    error : (...arg) => dev || (process.browser && typeof window.show_error !== 'undefined' && window.show_error) ? console.error(...arg) : {},
    info : (...arg) => dev || (process.browser && typeof window.show_info !== 'undefined' && window.show_info) ? console.info(...arg) : {},
    log : (...arg) => dev || (process.browser && typeof window.show_log !== 'undefined' && window.show_log) ? console.log(...arg) : {},
    warn : (...arg) => dev || (process.browser && typeof window.show_warn !== 'undefined' && window.show_warn) ? console.warn(...arg) : {}
}
